<template>
  <b-row>
    <b-col cols="12" md="6">
      <h4 class="mb-2">
        Request
      </h4>
      <h5>Entity</h5>
      <vx-json-viewer
        :value="request.entity"
        :expand-depth="4"
        expanded
        copyable
      />
      <h5>Body</h5>
      <vx-json-viewer
        :value="request.body"
        :expand-depth="4"
        expanded
        copyable
      />
    </b-col>
    <b-col cols="12" md="6">
      <h4 class="mb-2">
        Response
      </h4>
      <vx-json-viewer
        :value="response"
        :expand-depth="4"
        expanded
        copyable
      />
    </b-col>
  </b-row>
</template>

<script>
import VxJsonViewer from '@/components/VxJsonViewer'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'AdminLog',
  components: {
    VxJsonViewer,
    BRow,
    BCol
  },
  props: {
    log: {
      type: Object,
      default: () => ({})
    }
  },
  setup ({ log }) {
    const { response = {}, request = {} } = log

    return {
      response,
      request
    }
  }
}
</script>
