<template>
  <div>
    <vx-table
      :resource="resource"
      :columns="columns"
      :filters="filters"
    />
    <b-modal
      v-model="modalIsOpen"
      title="Admin Log"
      hide-footer
      title-tag="h4"
      size="xl"
      @close="closeModal"
    >
      <admin-log :log="entity" />
    </b-modal>
  </div>
</template>

<script>
import { VxTable, VxCellDate, VxCellButtons, VxCellLink } from '@/components/table'
import VxButton from '@/components/buttons/VxButton'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { admins } from '@/services/resources'
import { buildRoute, path } from '@/router'
import { filterTypes, sortOrders } from '@/services/table'
import { useModalEditor } from '@/services/modal'
import AdminLog from '@/views/admins/logs/AdminLog'

export default {
  name: 'AdminLogs',
  components: {
    AdminLog,
    VxButton,
    VxTable,
    VxCellDate
  },
  setup () {
    const resource = admins.logs
    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'user_id',
        label: 'Admin Id',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.admin, { params: { id } })
        })
      },
      { key: 'action_name' },
      { key: 'ip' },
      {
        key: 'details',
        component: VxCellButtons,
        tdAttr: {
          buttons: [{
            ...buttons.view,
            onClick: openModal
          }]
        }
      },
      {
        key: 'created_at',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'user_id',
        label: 'Admin Id',
        type: filterTypes.number
      },
      {
        key: 'ip',
        type: filterTypes.text
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from', 'to']
      },
      {
        key: 'action_names',
        type: filterTypes.serverSelect,
        resource: admins.logsActionNames,
        searchable: true,
        multiple: true,
        deselectFromDropdown: true
      }
    ]

    return {
      resource,
      columns,
      filters,

      modalIsOpen,
      entity,
      openModal,
      closeModal
    }
  }
}
</script>
